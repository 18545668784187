import img1 from '../assets/images/clients/1.png'
import img2 from '../assets/images/clients/2.jpg'
import img3 from '../assets/images/clients/3.png'
import img4 from '../assets/images/clients/4.png'
import img5 from '../assets/images/clients/5.jpg'
import img6 from '../assets/images/clients/6.jpg'

import img7 from '../assets/images/clients/7.jpg'
import img8 from '../assets/images/clients/8.png'
import img9 from '../assets/images/clients/9.jpg'
import img10 from '../assets/images/clients/10.png'
import img11 from '../assets/images/clients/11.jpg'
import img12 from '../assets/images/clients/12.png'

import img13 from '../assets/images/clients/13.png'
import img14 from '../assets/images/clients/14.jpg'
import img15 from '../assets/images/clients/15.jpg'
import img16 from '../assets/images/clients/16.png'
import img17 from '../assets/images/clients/17.png'
import img18 from '../assets/images/clients/18.png'

import img19 from '../assets/images/clients/19.jpg'
import img20 from '../assets/images/clients/20.jpg'
import img21 from '../assets/images/clients/21.jpg'
import img22 from '../assets/images/clients/22.jpg'
import img23 from '../assets/images/clients/23.png'
import img24 from '../assets/images/clients/24.png'

import img25 from '../assets/images/clients/25.png'
import img26 from '../assets/images/clients/26.jpg'
import img27 from '../assets/images/clients/27.png'
import img28 from '../assets/images/clients/28.png'
import img29 from '../assets/images/clients/29.png'
import img30 from '../assets/images/clients/30.png'

import img31 from '../assets/images/clients/31.png'
import img32 from '../assets/images/clients/32.png'
import img33 from '../assets/images/clients/33.png'
import img34 from '../assets/images/clients/34.jpg'
import img35 from '../assets/images/clients/35.png'
import img36 from '../assets/images/clients/36.png'

import img37 from '../assets/images/clients/37.png'
import img38 from '../assets/images/clients/38.png'
import img39 from '../assets/images/clients/39.jpg'
import img40 from '../assets/images/clients/40.jpg'
import img41 from '../assets/images/clients/41.jpg'
import img42 from '../assets/images/clients/42.jpg'

import img43 from '../assets/images/clients/43.png'
import img44 from '../assets/images/clients/44.png'
import img45 from '../assets/images/clients/45.png'
import img46 from '../assets/images/clients/46.jpg'
import img47 from '../assets/images/clients/47.jpg'
import img48 from '../assets/images/clients/48.png'


import img49 from '../assets/images/clients/49.jpg'
import img50 from '../assets/images/clients/50.png'
import img51 from '../assets/images/clients/51.jpg'
import img52 from '../assets/images/clients/52.jpg'
import img53 from '../assets/images/clients/53.png'
import img54 from '../assets/images/clients/54.png'


import img55 from '../assets/images/clients/55.jpg'
import img56 from '../assets/images/clients/56.png'
import img57 from '../assets/images/clients/57.png'
import img58 from '../assets/images/clients/58.jpg'
import img59 from '../assets/images/clients/59.jpg'
import img60 from '../assets/images/clients/60.png'


import img61 from '../assets/images/clients/61.png'
import img62 from '../assets/images/clients/62.png'
import img63 from '../assets/images/clients/63.png'
import img64 from '../assets/images/clients/64.jpg'
import img65 from '../assets/images/clients/65.png'
import img66 from '../assets/images/clients/66.jpg'

import img67 from '../assets/images/clients/67.jpg'
import img68 from '../assets/images/clients/68.png'
import img69 from '../assets/images/clients/69.jpeg'
import img70 from '../assets/images/clients/70.jpg'
import img71 from '../assets/images/clients/71.jpg'
import img72 from '../assets/images/clients/72.png'

import img73 from '../assets/images/clients/73.jpg'
import img74 from '../assets/images/clients/74.jpg'
import img75 from '../assets/images/clients/75.jpg'
import img76 from '../assets/images/clients/76.png'
import img77 from '../assets/images/clients/77.jpg'
import img78 from '../assets/images/clients/78.png'

import img79 from '../assets/images/clients/79.jpg'
import img80 from '../assets/images/clients/80.jpg'
import img81_0 from '../assets/images/clients/81_0.png'
import img81_1 from '../assets/images/clients/81_1.png'
import img81_2 from '../assets/images/clients/81_2.png'
import img81 from '../assets/images/clients/81.jpg'
import img82 from '../assets/images/clients/82.jpg'

import img83 from '../assets/images/clients/83.jpg'
import img84 from '../assets/images/clients/84.png'
import img85 from '../assets/images/clients/85.jpg'
import img86 from '../assets/images/clients/86.png'
import img87 from '../assets/images/clients/87.png'
import img88 from '../assets/images/clients/88.png'

import img89 from '../assets/images/clients/89.jpg'
import img90 from '../assets/images/clients/90.png'
import img91 from '../assets/images/clients/91.png'
import img92 from '../assets/images/clients/92.jpg'
import img93 from '../assets/images/clients/93.png'
import img94 from '../assets/images/clients/94.png'

import img95 from '../assets/images/clients/95.jpg'
import img96 from '../assets/images/clients/96.png'
import img97 from '../assets/images/clients/97.jpg'
import img98 from '../assets/images/clients/98.png'
import img99 from '../assets/images/clients/99.png'
import img100 from '../assets/images/clients/100.png'

import img101 from '../assets/images/clients/101.jpg'
import img102 from '../assets/images/clients/102.jpg'
import img103 from '../assets/images/clients/103.png'
import img104 from '../assets/images/clients/104.png'
import img105 from '../assets/images/clients/105.png'
import img106 from '../assets/images/clients/106.png'

import img107 from '../assets/images/clients/107.png'
import img108 from '../assets/images/clients/108.jpg'
import img109 from '../assets/images/clients/109.png'
import img110 from '../assets/images/clients/110.png'
import img111 from '../assets/images/clients/111.jpg'
import img112 from '../assets/images/clients/112.png'

import img113 from '../assets/images/clients/113.jpg'
import img114 from '../assets/images/clients/114.jpg'
import img115 from '../assets/images/clients/115.png'
import img116 from '../assets/images/clients/116.png'
import img117 from '../assets/images/clients/117.jpg'
import img118 from '../assets/images/clients/118.png'

import img119 from '../assets/images/clients/119.jpg'
import img120 from '../assets/images/clients/120.jpg'
import img121 from '../assets/images/clients/121.jpg'
import img122 from '../assets/images/clients/122.jpg'
import img123 from '../assets/images/clients/123.png'
import img124 from '../assets/images/clients/124.png'

import img125 from '../assets/images/clients/125.jpg'
import img126 from '../assets/images/clients/126.png'
import img127 from '../assets/images/clients/127.png'
import img128 from '../assets/images/clients/128.png'
import img129 from '../assets/images/clients/129.png'
import img130 from '../assets/images/clients/130.png'

import img131 from '../assets/images/clients/131.jpg'
import img132 from '../assets/images/clients/132.jpg'
import img133 from '../assets/images/clients/133.png'
import img134 from '../assets/images/clients/134.jpeg'
import img135 from '../assets/images/clients/135.png'


import img136 from '../assets/images/clients/Abbey healthcare.jpg'
import img137 from '../assets/images/clients/Acalis care.jpg'
import img138 from '../assets/images/clients/Alantra.png'
import img139 from '../assets/images/clients/Alpha-Logo-Columbia-Serial-Bold.png'
import img140 from '../assets/images/clients/BC8.png' //big
import img141 from '../assets/images/clients/Ethos-Partner-Identity-e1521734695884.jpg'

import img142 from '../assets/images/clients/Fidera.jpg'
import img143 from '../assets/images/clients/forest-new-logo.svg'
import img144 from '../assets/images/clients/grant thornton.png'
import img145 from '../assets/images/clients/learn 2 live.jpg' //big
import img146 from '../assets/images/clients/liaise-logo-v2.jpg' //big
import img147 from '../assets/images/clients/lifecare residences.svg'

import img148 from '../assets/images/clients/lifeways.svg'
import img149 from '../assets/images/clients/national care group.png'
import img150 from '../assets/images/clients/noble care logo.png'
import img151 from '../assets/images/clients/Phoenix.png'
import img152 from '../assets/images/clients/st Mathews logo-sm.png'
import img153 from '../assets/images/clients/swanton-logo (1).svg'

import img154 from '../assets/images/clients/Talbot 2.jpg'
import img155 from '../assets/images/clients/vgo-logo.svg'
import img156 from '../assets/images/clients/Warwick.png'

export const ImageArray=[
    img136,img137,img1,img2,img3,img4,
    img5,img139,img6,img7,img8,img9,
    img10,img11,img12,img13,img14,img15,
    img140,img16,img17,img18,
    img19,img20,img21,img22,img23,img24,
    img25,img26,img27,img28,img29,img30,
    img31,img32,img33,img34,img35,img36,
    img37,img38,img39,img40,img41,img42,
    img43,img44,img45,img46,img47,img48,
    img49,img50,img51,img52,img141,img53,
    img54,img55,img142,img143,img56,img57,
    img58,img59,img60,
    img61,img62,img63,img64,img65,img66,
    img67,img68,img69,img70,img71,
    img145,img72,img73,img146,img147,img74,
    img148,img75,img76,img77,img78,
    img79,img81_0,img81_1,img81_2,img80,
    img149,img81,img82,
    img150,img83,img84,img85,img86,
    img87,img88,img89,img90,img91,img92,
    img93,img94,img151,img95,img96,img98,
    img99,img100,img101,img102,img103,img104,
    img105,img106,img107,img108,img109,img110,
    img111,img112,img113,img114,img115,img152,
    img116,img117,img118,img153,img119,
    img154,img120,img121,img122,img123,
    img124,img125,img126,img127,img129,img155,
    img130,img131,img156,img132,img133,img134,


]

