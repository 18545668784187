import Img1 from '../assets/images/home/black_P502019Winner-Sector-Specialist-SmallBLACK[6].png'
import Img2 from '../assets/images/home/black_P502018Winner-Sector-Specialist-SmallBLACK[8].jpg'
import Img3 from '../assets/images/home/black_HIAwards2021Winner-Consultants-transactional-Small-black-01[94].png'
import Img4 from '../assets/images/home/black_Awards2016WinnerSmall[86].png'


import Img5 from '../assets/images/home/2019-logo 1.svg'
import Img6 from '../assets/images/home/2020-logo.svg'
import Img7 from '../assets/images/home/Power 50-Final.png'
import Img8 from '../assets/images/home/Proud-to-Support-Badge-e1601549997629 (1) 1.svg'

import Img9 from '../assets/images/home/health-investor-power-fifty-winner_logo-colour-RGB_V02 black.png'

export const awardsArray=[
    Img9,Img7,Img3,Img6,Img5,Img1,Img2,Img4,Img8
]